import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import confetti from 'canvas-confetti';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SuccessContainer = styled.div`
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
`;

const SuccessIcon = styled.div`
    font-size: 80px;
    color: #4CAF50;
    margin-bottom: 20px;
`;

const Title = styled.h2`
    color: #333;
    margin-bottom: 20px;
`;

const Message = styled.p`
    color: #666;
    font-size: 18px;
    margin-bottom: 30px;
`;

const DetailItem = styled.div`
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
`;

const DetailLabel = styled.span`
    font-weight: bold;
    color: #555;
`;

const DetailValue = styled.span`
    color: #333;
`;

const Button = styled(Link)`
    display: inline-block;
    background-color: #efbf04;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
        background-color: #efbf04;
    }
`;

const Success = () => {
    const query = useQuery();
    const sessionId = query.get('session_id');
    const [sessionDetails, setSessionDetails] = useState(null);
    const API_BASE_URL = 'https://api.student.youelite.net';

    useEffect(() => {
        // Trigger confetti effect
        confetti({
            particleCount: 300,
            spread: 100,
            origin: { y: 0.6 }
        });

        // Get the current token from localStorage
        const currentUser = JSON.parse(localStorage.getItem('user'));
        const token = currentUser?.token;

        if (token) {
            const updatedUser = { ...currentUser, active_free_trial: false };
            localStorage.setItem('user', JSON.stringify(updatedUser));

            fetch(`${API_BASE_URL}/api/user`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(response => response.json())
            .then(userData => {
                // Step 2: Extract only the required fields and update localStorage
                const updatedUser = {
                    token: token,
                    id: userData.id,
                    name: userData.name,
                    surname: userData.surname,
                    active_free_trial: false,
                    avatar: userData.avatar,
                    trial_started_at: '',
                    user_type: userData.user_type,
                };
                localStorage.setItem('user', JSON.stringify(updatedUser));

                // Step 3: Proceed with fetching session details as before
                if (sessionId) {
                    fetch(`${API_BASE_URL}/api/stripe/session/${sessionId}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            return response.json();
                        } else {
                            return response.text().then(text => {
                                throw new Error(`Unexpected content-type: ${contentType}\nResponse: ${text}`);
                            });
                        }
                    })
                    .then(data => {
                        setSessionDetails(data);
                      
                        // Extract plan details
                        const planName = data.plan_name || 'Premium';
                        const planId = data.plan_id || 'premium_plan';
                        const amountPaid = data.amount_total ? data.amount_total / 100 : 0; // Convert from cents to dollars
                      
                        // Track the successful purchase (eCommerce tracking: purchase)
                        ReactGA.event('purchase', {
                          transaction_id: data.id,  // Use the transaction ID from your session details
                          currency: 'USD',
                          value: amountPaid,
                          items: [{
                            item_name: planName,
                            item_id: planId.toString(),
                            price: amountPaid,
                          }],
                        });
                    })
                    .catch(error => console.error('Error fetching session details:', error));
                }
            })
            .catch(error => console.error('Error fetching user details:', error));
        }
    }, [sessionId]);

    if (!sessionDetails) {
        return (
            <SuccessContainer>
                <Title>Processing Your Payment</Title>
                <Message>Please wait while we confirm your purchase...</Message>
            </SuccessContainer>
        );
    }

    return (
        <SuccessContainer>
            <SuccessIcon>🎉</SuccessIcon>
            <Title>Payment Successful!</Title>
            <Message>Thank you for your purchase. Your account has been upgraded successfully.</Message>
            
            <DetailItem>
                <DetailLabel>Order ID: </DetailLabel>
                <DetailValue>{sessionDetails.id || 'N/A'}</DetailValue>
            </DetailItem>
            
            <DetailItem>
                <DetailLabel>Amount Paid: </DetailLabel>
                <DetailValue>${sessionDetails.amount_total / 100 || 'N/A'}</DetailValue>
            </DetailItem>
            
            <DetailItem>
                <DetailLabel>Plan: </DetailLabel>
                <DetailValue>{sessionDetails.plan_name || 'Premium'}</DetailValue>
            </DetailItem>
            
            <Button to="/guest-question">Submit a new question</Button>
            <Button to="/signup">Create a new account!</Button>
        </SuccessContainer>
    );
};

export default Success;